import { createContext, useState } from 'react';


const ActionPopupContext = createContext();

const ActionModalContextProvider = ({ children }) => {
    const [isActionPopupOpened, setIsActionPopupOpened] = useState(false);
    const [actionData, setActionData] = useState(null);

    const handleActionPopupOpen = async ({ isOpened = true, actionData = null }) => {
        setActionData(null)
        if (isOpened === true) {
            if (actionData.canBeValidated === 1) {
                setActionData({
                    text: `Validate ${actionData.treatCoinsEarned}TC of '${actionData.actionName}'`,
                    heading: actionData.appName,
                    ...actionData,
                    coinsEarningID: actionData.coinsEarningID
                })
            } else {
                setActionData(actionData);
            }
        }
        setIsActionPopupOpened(isOpened);
    };

    return (
        <ActionPopupContext.Provider value={{ isActionPopupOpened, handleActionPopupOpen, actionData, setActionData }}>
            {children}
        </ActionPopupContext.Provider>
    );
};

export { ActionModalContextProvider, ActionPopupContext };

