import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

export const apiGetProviders = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.getProvidersURL}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    
    return await res.json();

}
export const apiOfferGetStatisticsByProviders = async () => {

try {
    const res = await fetch(`${Backend.backendURLs.offerGetStatisticsByProvidersURL}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        Alerts.showErrorAlert('Something went wrong');
    }
} catch (error) {
    Alerts.showErrorAlert('Something went wrong');
}

}


export const apiAddProvider = async (providerName) => {

    const res = await fetch(Backend.backendURLs.addNewProviderUrl, {
        method: Backend.backendMethods.post,
        body: JSON.stringify({
            providerName
        }),
        headers: Backend.generateHeader()
    })

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}