import React from 'react';
import SelectModalAddDetailedDescriptionForm from './Select_Modal_Add_Detailed_Description_Form.js';
import { InputLabel, TextField } from '@mui/material';

const content = require('content.json');

function SelectContainerModalAddDescriptionForm({ 
    items=[], 
    isNumber,
    values, 
    value,
    placeholder,
    isDetailed,
    addItemHandler, 
    removeItemHandler,
    changeDescriptionHandler
}) {
    const addAnotherHandler = () => {
        if (values.length>=4) return;
        const newElem = { stepID: values.length+1 };
        addItemHandler(newElem);
    }

    const removeHandler = (id) => () => {
        if (values.length===1) return;
        removeItemHandler(id);
    }

    const changeHandler = (id, newValue, stepID, text) => {
        const elem = values.find(elem => elem.stepID===stepID);
        elem.id = newValue;
        elem.value = text;
    }

    return (
        <div className='select-container'>
            {
                isDetailed ?
                <h2 className='text-left comment-ticket-popup-description'>{ content.choose_description }</h2> :
                null
            }
            {
                isDetailed ?
                values.map((each, idx) => (
                    <SelectModalAddDetailedDescriptionForm
                        key={each.stepID}
                        stepID={each.stepID}
                        id={idx+1}
                        defaultValue={each.value}
                        options={items}
                        isDetailed={isDetailed}
                        addAnotherHandler={addAnotherHandler}
                        removeHandler={removeHandler}
                        valueChangeHandler={changeHandler}
                    />
                )) :
                <div className='quiz-option-container'>
                        <TextField
                            style={{ marginBottom: 15 }}
                            multiline
                            fullWidth
                            rows={5}
                            type="text"
                            size="big"
                            placeholder={placeholder}
                            value={value} 
                            onChange={(e) => changeDescriptionHandler(e.target.value)}
                            className="quiz-option-data-text"
                        />

                </div>
            }
        </div>
    )
}

export default SelectContainerModalAddDescriptionForm;