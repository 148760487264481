import React, { useEffect, useState } from 'react';
import { apiGetLiveNewsAdmin } from 'services/LiveNewsService';
import { Grid, InputLabel, TextField } from '@mui/material';

const content = require('content.json');

function AddLiveNewsModalForm({ handleCancel, handleConfirm, confirmCB, setIsLoading }) {
    const [newsContent, setNewsContent] = useState('');

    const getCurrentLiveNews = async () => {
        setIsLoading()
        const { Value } = await apiGetLiveNewsAdmin();
        console.log(Value);
        if (Value) {
            setNewsContent(Value);
        }
        setIsLoading()
    };
    useEffect(() => {
        getCurrentLiveNews();
    }, [])


    const handleSubmit = () => {
        confirmCB(newsContent);
    }
  return (
      <div>
          <Grid
              container
              direction="row"
              spacing={3}
          >
              <InputLabel
                  style={{ marginBottom: 5 }}
                  >Content</InputLabel>
              <TextField
                  style={{ marginBottom: 15 }}
                  multiline
                  fullWidth
                  value={newsContent}
                  onChange={(e) => {
                      setNewsContent(e.target.value)
                  }}
                  rows={5}
                  type="text"
                  size="big"
              />
              <InputLabel
                  style={{ marginBottom: 20 }}
              >*To remove admin live news clear text and press confirm</InputLabel>
              </Grid>
          <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
          <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.confirm}</button>
    </div>
  )
}

export default AddLiveNewsModalForm