import Backend from 'Backend.js';
import Alerts from 'utils/Alerts.js';

export const apiGetLiveNewsAdmin = async () => { 
    try {
        const res = await fetch(`${Backend.backendURLs.getAdminLiveNewsURL}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        });
        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            return await res.json();
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }
    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }
};

export const apiPostLiveNews = async (newsContent) => {
    try {
        const res = await fetch(`${Backend.backendURLs.addLiveNewsURL}`, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify({ newsContent }),
        });
        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            Alerts.showSuccessAlert('Live News added successfully');
            return res;
        } else {
            const { message } = await res.json();
            Alerts.showErrorAlert(message);
        }

    } catch (error) {
        Alerts.showErrorAlert('Something went wrong' || error.message);
    }
};