import { Checkbox, InputLabel } from '@mui/material';
import React, { useState } from 'react';

const content = require('content.json');

function ValidateCoinsModalForm({
    actionData,
    handleCancel,
    handleConfirm,
}) {
    const [data, setData] = useState(actionData);
    return (
        <div className='margin-top-20 '>
            <p className='margin-bottom-20'>{`You are about to validate coins earning with ID: ${actionData.coinsEarningID}`}</p>
            <div className='flex-row items-center justify-center'>
                <Checkbox
                    size="small"
                    className="grid-list_basic-cell-checkbox"
                    checked={data?.sendNotification}
                    onChange={(e) => {
                        setData(prev => ({ ...prev, sendNotification: e.target.checked }));
                    }}
                />
                <InputLabel htmlFor="reason-field">Send notification to user</InputLabel>
            </div>
            <button onClick={() => {
                actionData.handleConfirm(data)
                handleConfirm()
            }} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>Validate</button>
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
        </div>
    );
}

export default ValidateCoinsModalForm;