import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

export const apiGetUserCoinsEarning = async (filter) => {
    try {
        const queryString = new URLSearchParams(filter).toString();

        const res = await fetch(`${Backend.backendURLs.userCoinsEarningURL}?${queryString}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader()
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            return await res.json();
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }
    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }

};


export const apiUserValidateCoins = async (coinsEarningID, generateUserNotification, uniqueUserID) => {
    try {
        const res = await fetch(`${Backend.backendURLs.userValidateCoinsURL}`, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify({ coinsEarningID, uniqueUserID, generateUserNotification: generateUserNotification ? 1 : 0 }),
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } 
        else if (res.status === Backend.backendResponseCodes.OKCode) {
            Alerts.showSuccessAlert(`Validated coins earning with ID: ${coinsEarningID}`)
        } 
        else if (res.status === Backend.backendResponseCodes.badRequestCode) {
            let response = await res.json();
            if (response.message) {
                Alerts.showErrorAlert(response.message);
            }
            else {
                Alerts.showErrorAlert('Something went wrong');
            }
        }   
        else {
            Alerts.showErrorAlert('Something went wrong');
        }


    } catch (error) {
        Alerts.showErrorAlert(error.message || 'Something went wrong');
    }
};

export const apiUserSimulateChargeback = async (data) => {
    try {
        const res = await fetch(`${Backend.backendURLs.userSimulateChargebackURL}`, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            Alerts.showSuccessAlert(`Simulated Chargeback coins earning with ID: ${data.originalRequestID}`);
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }


    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }
};