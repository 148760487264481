import React, { useState } from 'react';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';
import SelectContainerModalAddDescriptionForm from './Select_Container_Modal_Add_Description_Form.js';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const loadURL = backendURLs.editOfferProviderUrl;

export default function EditCallbackSecretModalForm({ id, value, handleCancel, handleConfirm, confirmCB }) {
    const [callbackSecret, setCallbackSecret] = useState(value);

    const handleSubmit = async () => {
        if (!callbackSecret.trim()) return;

        const sendData = { providerID: id, callbackSecret };
        try {
            const response = await sendDataHandler(sendData);
            
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data ? data.message: response.statusText);
            }
            
            handleConfirm();
            
            if (data) {
                Alerts.showSuccessAlert(data.message);
            }
            
            await confirmCB(callbackSecret);
            
        } catch (err) {
            Alerts.showErrorAlert(err.message);
        }
    }

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        })
    }

    return (
        <div>
            <SelectContainerModalAddDescriptionForm
                value={callbackSecret}
                placeholder={content.edit_callback_secret}
                changeDescriptionHandler={(value) => setCallbackSecret(value)}
            />

            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{ content.cancel }</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{ value ? content.edit : content.add }</button>
        </div>
    )
}
