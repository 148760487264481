import styled from '@emotion/styled';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Header from 'components/text/Header.js';
import React, { useState } from 'react';
import ProvidersTab from '../components/providers/Providers_Tab';
import OffersStatisticsByProviderTab from '../components/providers/Offers_Statistics_By_Provider_Tab';

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: 0
}));


const Providers = () => {

    const [currentTab, setCurrentTab] = useState('1');
    const [tabType, setTabType] = useState(null);


    const onTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <div className='upload-content'>
            <Header />
            <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={onTabChange}
                        variant="scrollable"
                        scrollButtons={true}
                    >
                        <Tab label="Providers" value="1" />
                        <Tab label="Offers statistics by Provider" value="2" />
                    </TabList>
                </Box>
                <StyledTabPanel value="1">
                    <ProvidersTab />
                </StyledTabPanel>
                <StyledTabPanel value="2">
                    <OffersStatisticsByProviderTab />
                </StyledTabPanel>
            </TabContext>

        </div>
    );

};

export default Providers;