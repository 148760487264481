import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, Paper, TextField, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Alerts from 'utils/Alerts.js';
import DataTable from '../tables/DataTable';
import { apiGetProviders } from '../../services/ProviderService';

const initialFilter = {
    "order": "desc",
    "orderBy": "ProviderID",
    "page": 1,
    "pageSize": 25,
    "search": "",
    "searchBy": ""
};

function ProvidersTab() {
    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [total, setTotal] = useState(0);


    const fetchData = async () => {
        setLoader(true);
        const providers = await apiGetProviders(filter);
        setLoader(false);
        if (providers.result) {
            setRows(providers.result);
            setTotal(providers.total);
        }
    };

    useEffect(() => {

        fetchData();

    }, [filter]);

    const columns = [
        {
            id: 'ProviderID',
            label: 'ID',
        },
        {
            id: 'Prefix',
            label: 'Prefix',
        },
        {
            id: 'ProviderName',
            label: 'Name',
        },
        {
            id: 'APIKey',
            label: 'API key',
            parse: (val, row) => {
                return (
                    <span className='text-underline-hover' onClick={(e) => {
                        e.stopPropagation();
                        Alerts.showEditAPIKeyAlert(row.ProviderID, row.APIKey, (value) => {
                            const updatedRows = rows.map(oldRow =>
                                oldRow.ProviderID === row.ProviderID
                                    ? { ...oldRow, APIKey: value }
                                    : oldRow
                            );
                            setRows(updatedRows);
                        }
                        );
                    }}>
                        {val}
                    </span>
                );
            }
        },
        {
            id: 'callbackKey',
            label: 'Callback key'
        },
        {
            id: 'callbackSecret',
            label: 'Callback secret',
            parse: (val, row) => {
                return (
                    <span className='text-underline-hover' onClick={(e) => {
                        e.stopPropagation();
                        Alerts.showEditCallbackSecretAlert(row.ProviderID, row.callbackSecret, (value) => {
                            const updatedRows = rows.map(oldRow =>
                                oldRow.ProviderID === row.ProviderID
                                    ? { ...oldRow, callbackSecret: value }
                                    : oldRow
                            );
                            setRows(updatedRows);
                        });
                    }}>
                        {val}
                    </span>
                );
            }
        }
    ];

    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter }));
    };
  return (
      <div className="content-container">
          <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%', mb: 2 }}>
                  <Toolbar
                      sx={{
                          pl: { sm: 2 },
                          pr: { xs: 1, sm: 1 },
                          justifyContent: "space-between"
                      }}
                  >
                      <div>
                          <TextField
                              sx={{
                                  "& *": {
                                      fontFamily: 'inherit',
                                  },
                                  minWidth: '200px',
                                  marginRight: '10px'
                              }}
                              size="small"
                              className="grid-list_flex-form_input"
                              id="filter-query-search"
                              label="Search"
                              type="search"
                              onChange={(e) => {
                                  setFilter(prev => ({
                                      ...prev,
                                      search: e.target.value,
                                      searchBy: 'name'
                                  }));
                              }}
                              InputProps={{
                                  endAdornment: <InputAdornment position="end">{<SearchIcon style={{ opacity: 0.5 }} />}</InputAdornment>
                              }}
                          />
                          <Button
                              variant="outlined"
                              onClick={() => Alerts.showAddNewProviderAlert(() => fetchData())}
                          >
                              Add Provider
                          </Button>
                      </div>
                  </Toolbar>
                  <DataTable
                      columns={columns}
                      tableData={rows}
                      onFilterChange={onFilterChange}
                      filter={filter}
                      loading={loader}
                      total={total}
                      fullHeight
                  />
              </Paper>
          </Box>
      </div>
  )
}

export default ProvidersTab