import { Box, Paper, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiOfferGetStatisticsByProviders } from 'services/ProviderService.js';
import DataTable from 'components/tables/DataTable';

const columns = [
    {
        id: 'ProviderID',
        label: 'ID',
        sortable: false,
    },
    {
        id: 'Name',
        label: 'Provider Name',
        sortable: false,
    },
    {
        id: 'PostbacksAmountThisMonth',
        label: 'Postbacks This Month',
        sortable: false,
    },
    {
        id: 'Active',
        label: 'Active',
        sortable: false,
    },
    {
        id: 'WaitingForAdminApprovalFor30Days',
        label: 'Waiting for Admin Approval (30 Days)',
        sortable: false,
    },
    {
        id: 'Deactivated',
        label: 'Deactivated',
        sortable: false,
    },
    {
        id: 'UnavailableDueToCaps',
        label: 'Unavailable Due to Caps',
        sortable: false,
    },
    {
        id: 'RemovedByProvider',
        label: 'Removed by Provider',
        sortable: false,
    },
    {
        id: 'NotAvail',
        label: 'Not Available',
        sortable: false,
    }
];

function OffersStatisticsByProviderTab() {
    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);

    const fetchData = async () => {
        setLoader(true);
        const res = await apiOfferGetStatisticsByProviders();
        setLoader(false);
        if (res?.result.length > 0) {
            setRows(res.result);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="content-container">
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            justifyContent: "space-between"
                        }}
                    >
                    </Toolbar>
                    <DataTable
                        columns={columns}
                        tableData={rows}
                        loading={loader}
                        fullHeight
                    />
                </Paper>
            </Box>
        </div>
    );
}

export default OffersStatisticsByProviderTab;