import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'components/tables/DataTable.js';
import { apiGetUserCoinsEarning } from 'services/TreatCoinsFeedService.js';
import ActionButton from 'components/buttons/Action_Button';
import DropDown from 'components/inputs/DropDown.js'
import DataFilter from 'components/filters/DataFilter.js';
import SearchField from '../inputs/SearchField';
import { ActionPopupContext } from 'contexts/Action_Modal_Context';
import { apiUserValidateCoins, apiUserSimulateChargeback } from '../../services/TreatCoinsFeedService';
import { useSearchParams } from 'react-router-dom';
import Alerts from '../../utils/Alerts';

const initialFilter = {
    "uniqueUserID": '',
    "page": 1,
    "pageSize": 20,
    "status": "5",
    "order": 'asc',
    "oderBy": '',
    "searchTerm": "",
};

const statusTypes = [
    { label: '-', value: '' },
    { label: 'usable', value: 1 },
    { label: 'competition ', value: 2 },
    { label: 'cooking ', value: 3 },
    { label: 'used ', value: 4 },
    { label: 'all ', value: 5 },
];

function TreatCoinsFeed({ status, user }) {
    if (status) {
        initialFilter['status'] = status;
    }
    let [searchParams] = useSearchParams();
    const uniqueUserID = searchParams.get('uniqueUserID') || user.uniqueID;

    const { handleActionPopupOpen } = useContext(ActionPopupContext);
    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState({ ...initialFilter, uniqueUserID });
    const [total, setTotal] = useState(0);

    const validateEarning = async ({ uniqueUserID, coinsEarningID, sendNotification, }) => {
        console.log({ uniqueUserID, coinsEarningID, sendNotification, })
        setLoader(true);
        await apiUserValidateCoins(coinsEarningID, sendNotification, uniqueUserID);
        setFilter({ ...initialFilter, status: 5, uniqueUserID });
        setLoader(false);
    };

    const simulateChardback = async (actionData) => {
        setLoader(true);
        await apiUserSimulateChargeback(actionData.requestData);
        setFilter({ ...initialFilter, status: 5, uniqueUserID });
        setLoader(false);
    };

    const columns = [
        {
            id: 'date',
            label: 'Date Created',
        },
        {
            id: 'providerName',
            label: 'Provider Name',
        },
        {
            id: 'uniqueOfferID',
            label: 'Unique Offer ID',
        },
        {
            id: 'appName',
            label: 'Offer App Name',
        },
        {
            id: 'actionName',
            label: 'Action',
        },
        {
            id: 'coinsEarningType',
            label: 'Coins Earning State Rename',
        },
        {
            id: 'treatCoinsEarned',
            label: 'TCs Earned',
        },
        {
            id: 'postbackRequestID',
            label: 'Postback Request ID',
        },
        {
            id: 'manualByAdminName',
            label: 'Manual by Admin Name',
        },
        {
            id: 'validatedByAdminName',
            label: 'Validated by Admin Name',
        },
        {
            id: 'operationType',
            label: 'Operation Type',
        },
        {
            id: 'coinsEarningID',
            label: 'Coins Earnings ID',
        },
        {
            id: 'canBeValidated',
            type: 'action_button',
            label: 'Validate',
            parse: (val, row) => {
                if (row.canBeValidated) {
                    return (
                        <ActionButton
                            text='Validate'
                            type={''}
                            onClick={() => {
                                Alerts.validateCoinsAlert({
                                    uniqueUserID,
                                    coinsEarningID: row.coinsEarningID,
                                    sendNotification: true,
                                    handleConfirm: validateEarning,
                                });
                            }}
                        />
                    );
                }
            }
        },
        {
            id: 'canSendChargeback',
            type: 'action_button',
            label: 'Simulate Chargeback',
            parse: (val, row) => {
                const { providerID, coinsEarningID, uniqueOfferID, postbackRequestID } = row;
                if (row.canSendChargeback) {
                    return (
                        <ActionButton
                            text='Simulate'
                            type={''}
                            onClick={() => {
                                handleActionPopupOpen({
                                    isOpened: true,
                                    actionData: {
                                        requestData: {
                                            uniqueUserID,
                                            uniqueOfferID,
                                            amount: -1,
                                            originalRequestID: postbackRequestID,
                                            providerID,
                                        },
                                        text: `You are about to undo this Coinsearnings transaction ${coinsEarningID}`,
                                        heading: `Simulate Chargeback`,
                                        handleConfirm: simulateChardback,
                                        ...row,
                                        confirmBtnText: 'Simulate',
                                    },
                                });
                            }}
                        />
                    );
                }
            }
        }
    ];

    const fetchData = async () => {
        setLoader(true);
        const earnings = await apiGetUserCoinsEarning(filter);
        setLoader(false);
        if (earnings) {
            setRows(earnings.coinsEarnings);
            setTotal(earnings.total);
        }
    };

    const onFilterChange = filterObj => {
        setFilter(prev => ({ ...prev, ...filterObj, status: filterObj.status ? filterObj.status : 5, page: filterObj.page ? filterObj.page : 1 }));
    };

    const onPageChange = page => {
        setFilter(prev => ({ ...prev, page }));
    };
    const onDropDownChange = e => {
        setFilter(prev=>({...prev, status: e.target.value}))
    }

    useEffect(() => {
        fetchData();
    }, [filter]);

    return (
            <div className='user-table-container'>
                <div className="user-table-handlers">
                    <p className="user-table-search-name">Treat Coins Earnings</p>
                    <div className='flex justify-between gap-5 '>
                        <DataFilter onChange={onFilterChange} submitLabel="Submit">
                            <SearchField name='searchTerm' label='Search' />
                        <DropDown
                            onChange={onDropDownChange}
                            name='status'
                            label='Status'
                            data={statusTypes}
                            value={filter.status} />
                        </DataFilter>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    tableData={rows}
                    onPageChange={onPageChange}
                    onFilterChange={onFilterChange}
                    filter={filter}
                    loading={loader}
                    total={total}
            />
        </div>
    );
}

export default TreatCoinsFeed;